import React from "react"

const MassyoSyorui = () => {
  const title = `抵当権抹消手続`
  const content = `抵当権抹消登記には不動産１個につき、１,０００円の登録免許税という税金を納付する必要があります。
  
  例えば、戸建住宅で、土地１筆、建物１戸の場合は、２,０００円の登録免許税となります。
  マンションの場合、敷地の土地についても登録免許税が課税されるので、専有部分が１部屋、敷地権の土地が１筆の場合、戸建住宅と同様に２,０００円の登録免許税の納付が必要となります。
 
  なお、その他に、申請対象物件の現状調査のための登記事項証明書等の取得費用や、申請後に、申請した通りに登記がなされているかを確認するための登記事項証明書等の取得費用も土地の筆数や、建物の戸数の分だけかかることになります。
 
  また、所有者の登記されている住所・氏名が、現在の住所・氏名と異なる場合は、併せて住所・氏名変更登記が必要となります。
 
  抵当権抹消登記手続きに必要な書類は下記の通りとなります。
`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．抵当権解除証書等の登記原因の分かるもの</dt>
            <dd>
              金融機関から所有者宛ての書類で、完済日をもって、抵当権が解除された旨や、被担保債権が完済された旨が記載されている書類です。
              金融機関によって、書類の名称は異なり、当初の抵当権設定契約書に、抵当権を解除する旨の記載をする金融機関もあります。
            </dd>

            <dt>２．抵当権設定契約書または、登記識別情報通知</dt>
            <dd>
              抵当権設定契約書等に、法務局の登記済の朱色の受付印に受付日付、受付番号が記載されたものか、もしくは、登記識別情報通知という不動産ごとに発行された登記名義人欄に金融機関の名前が記載された書類です。
              <br />
              管轄法務局のオンライン庁指定日以降は、登記済の受付印に代えて、登記識別情報通知が発行されるようになったため、当初の登記された時期により異なります。
            </dd>

            <dt>３．委任状</dt>
            <dd>
              金融機関発行の抵当権抹消を委任する旨の委任状が必要です。
              <br />
              また、司法書士に依頼される場合は、所有者の委任状も必要となります。
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default MassyoSyorui
