import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import SEO from "../components/seo"
import MassyoSyorui from "../components/massyoDetail/massyoSyorui"
import MassyoCost from "../components/massyoDetail/massyoCost"
import BackContact from "../components/backContact"
import BlogLink from "../components/blogLink"

const Erase = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      massyo: file(relativePath: { eq: "loan.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "住宅ローンの返済や、その他の融資を完済した場合、抵当権等の担保権の抹消の登記が必要となります。その不動産手続きの費用などについて、大阪の司法書士事務所の司法書士法人みつわ合同事務所がご紹介します。"

  return (
    <Layout>
      <SEO
        description={description}
        title="抵当権抹消　登記 | 司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.massyo.childImageSharp.original.src}
        pageimgw={data.massyo.childImageSharp.original.width}
        pageimgh={data.massyo.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"massyo"}
          fluid={data.massyo.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"抵当権抹消"}
        />
        <section className="massyo">
          <div className="massyo__container">
            <h1 className="massyo__title">抵当権抹消</h1>
            <MassyoSyorui />
            <MassyoCost />
            <BlogLink link={"erase"} linkName={"抵当権抹消"} />
          </div>
        </section>
        <BackContact link={"/regist/"} linkName={"戻る"} />
      </div>
    </Layout>
  )
}

export default Erase
